<template>
  <v-container fluid>
    <v-data-iterator
      :items="formattedLaws"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      no-data-text=""
      no-results-text=""
      hide-default-footer
    >
      <template v-slot:header>
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <v-toolbar
              color="secondary"
              class="mb-1"
              flat
              rounded
            >
              <v-toolbar-title class="white--text">Laws and Regulations</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer />
              <v-text-field
                v-model="search"
                clearable
                flat
                solo
                dense
                rounded
                class="d-none d-sm-flex"
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-text-field>
              <v-spacer />
              <v-btn
                small
                class="ms-2 primary"
                rounded
                @click="goToLawEdit('new')"
                >New Law</v-btn
              >
            </v-toolbar>
          </v-col>
        </v-row>
        <v-card-title class="d-flex d-sm-none align-center flex-wrap">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo
            outlined
            dense
            rounded
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
          ></v-text-field>
        </v-card-title>
      </template>

      <template v-slot:default="{ items, isLoading }">
        <v-row>
          <template v-if="isLoading">
            <v-col
              cols="12"
              md="8"
              offset-md="2"
              v-for="n in itemsPerPage"
              :key="n"
            >
              <v-skeleton-loader type="card" class="mb-4"></v-skeleton-loader>
            </v-col>
          </template>

          <template v-else>
            <v-col
              v-for="law in items"
              :key="law.id"
              cols="12"
              md="8"
              offset-md="2"
            >
              <v-card style="min-height: 100%;" class="justify-center position-relative" elevation="3">
                <!-- Background image div -->
                <v-img
                  v-if="lawImage"
                  alt="Protected"
                  :src="lawImage"
                  contain
                  cover
                  no-repeat
                  position="right"
                  :style="{
                    opacity: 0.5,
                    position: 'absolute',
                    top: '10%',
                    right: '2%',
                    bottom: '10%',
                    zIndex: 0,
                  }"
                ></v-img>
                <v-chip
                  label
                  small
                  color="secondary"
                  class="text-overline ml-2 my-2"
                >
                  {{ law.lawTag }}
                </v-chip>
                <v-chip
                  v-if="getLawCode(law.lawTag, law.lawNo)"
                  label
                  small
                  color="primary"
                  class="text-overline ml-1 my-2"
                >
                  {{ getLawCode(law.lawTag, law.lawNo) }}
                </v-chip>
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-subtitle class="text-right">
                      {{ law.approvalDate }}
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-h5 mb-1">
                      {{ law.officialTitle }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ law?.longTitle ? law.longTitle : law.aka }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-3"
                        fab
                        small
                        color="primary"
                        elevation="7"
                        raised
                        v-bind="attrs"
                        v-on="on"
                        @click="lawView(law.id)"
                      >
                        <v-icon small>mdi-book-open-page-variant-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Open Law</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-3"
                        fab
                        small
                        color="primary"
                        elevation="7"
                        raised
                        v-bind="attrs"
                        v-on="on"
                        @click="goToLawEdit(law.id)"
                      >
                        <v-icon small>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Law</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mr-6"
                        fab
                        small
                        color="primary"
                        elevation="7"
                        raised
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteLaw(law)"
                      >
                        <v-icon small>mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Law</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </template>

      <template v-slot:no-results>
        <v-empty-state
          icon="mdi-alert-circle-outline"
          title="No matching laws found"
          description="Try a different search term."
        />
      </template>

      <template v-slot:no-data>
        <v-empty-state
          icon="mdi-alert-circle-outline"
          title="No laws have been published"
          description="Your law collection is currently empty."
        />
      </template>

      <template v-slot:footer>
        <v-row class="mt-2" align="center" justify="center">
          <v-col cols="12" md="8">
            <v-row align="center" justify="center">
              <v-pagination
                v-model="page"
                :length="numberOfPages"
                :total-visible="5"
                circle
                color="primary"
              ></v-pagination>
            </v-row>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>

    <!-- Delete Confirmation Modal -->
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
      <v-card outlined>
        <v-card-title class="headline">Confirm Deletion</v-card-title>
        <v-card-text>
          Are you sure you want to delete <strong class="primary--text">"{{ lawTitleToDelete }}"</strong> ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" small outlined @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="primary" small @click="confirmDelete">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
  </v-container>
</template>

<script>
// import { lawsCollection, sectionsCollection } from "@/firebaseDb"; 
import moment from "moment"; 
import { mapGetters, mapActions } from "vuex";
import useLocalStorage from '@/composables/useLocalStorage';
import VEmptyState from '../VEmptyState.vue';

export default {
  data() {
    return {
      search: '',
      itemsPerPage: 5,
      page: 1,
      snackbar: false,
      snackColor: 'success',
      response: '',
      lawImage: null,
      deleteDialog: false,
      lawIdToDelete: null,
      lawTitleToDelete: '',
      isLoading: true,
    };
  },
  created () {
    this.fetchLaws().then(() => {
      this.isLoading = false;  // Set loading state to false after fetching data
    });
    const lawImageStorage = useLocalStorage(null, "lawImage");
    this.lawImage = lawImageStorage.get();
  },
  components: {
    VEmptyState,
  },
  computed: {
    ...mapGetters(["darkTheme", "getLaws"]),
    formattedLaws() {
      return (this.getLaws || []).map(law => {
        let formattedDate = 'N/A';
        if (law.approvalDate) {
          if (law.approvalDate.toDate) {
            formattedDate = moment(law.approvalDate.toDate()).format('LL');
          } else if (law.approvalDate instanceof Date) {
            formattedDate = moment(law.approvalDate).format('LL');
          } else if (typeof law.approvalDate === 'string') {
            formattedDate = moment(law.approvalDate).format('LL');
          }
        }
        return {
          ...law,
          approvalDate: formattedDate,
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.getLaws.length / this.itemsPerPage); // Use getLaws here
    },
  },
  methods: {
    ...mapActions(["fetchLaws", "deleteLaw"]),
    getImageUrl(imageFileName) {
      try {
        return require(`@/assets/${imageFileName}`);
      } catch (e) {
        console.error(`Image ${imageFileName} not found in assets.`);
        return '';
      }
    },
    getLawCode(lawTag, lawNo) {
      // Return early for tags that do not require a law number
      if (lawTag === "Constitutional Document" || lawTag === "Spanish Decree") {
        return "";
      }
      const lawTagMap = {
        "Republic Act": "RA",
        "Executive Order": "EO",
        "Presidential Decree": "PD",
        "Batas Pambansa": "BP",
        "Letters of Instruction": "LOI",
        "Commonwealth Acts": "CA",
        "Acts of Legislature": "Act"
      };
      // Look up the prefix for the lawTag, if it exists
      const prefix = lawTagMap[lawTag] || "";
      return lawNo ? `${prefix} ${lawNo}` : "";
    },
    goToLawEdit(id) {
      this.$router.push({ name: 'laws-edit', params: { id } });
    },
    lawView(lawId) {
      this.$router.push({ name: 'laws-and-regulations', params: { id: lawId } });
    },
    deleteLaw(law) {
      // console.log(law);
      this.lawIdToDelete = law.id;
      this.lawTitleToDelete = law.officialTitle;
      this.deleteDialog = true;
    },
     async confirmDelete() {
      try {
        await this.deleteLaw(this.lawIdToDelete);
        this.snackColor = "success";
        this.response = "Law and its sections deleted successfully";
        this.snackbar = true;
      } catch (error) {
        this.snackColor = "error";
        this.response = "Error deleting law or its sections";
        this.snackbar = true;
        console.error("Error deleting law or its sections:", error);
      } finally {
        this.deleteDialog = false;
        this.lawIdToDelete = null;
        this.lawTitleToDelete = "";
      }
    },
  },
};
</script>
